<template lang="pug">
  .new-project
    h1 Har du et Projekt?
    p Jeg er altid frisk på lidt sparing. Mangler du en udvikler til lidt små opgaver så er jeg også frisk på det.
    p Her under kan du set et udpluk af nogle af de projekter jeg har arbejdet på.
</template>

<script>
export default {
  name: "NewProject"
};
</script>
