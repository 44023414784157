<template lang="pug">
  .flip-card
    .flip-card__inner
      .flip-card__front
        slot(name="front")

      .flip-card__back
        slot(name="back")
</template>

<script>
export default {
  name: "flip-card"
};
</script>

<style scoped lang="sass">
$block: "flip-card"

+component
  background-color: transparent
  width: 250px
  height: 250px
  perspective: 1000px

  &:hover
    +then(inner)
      transform: rotateX(180deg)

  +has(inner)
    position: relative
    width: 100%
    height: 100%
    text-align: center
    transition: transform 0.5s
    transform-style: preserve-3d

  +has([front,back])
    width: 100%
    height: 100%
    position: absolute
    backface-visibility: hidden
    border: 1rem solid #333

  +has(front)
    background: #333

  +has(back)
    color: white
    background: #333
    transform: rotateX(180deg)
</style>
