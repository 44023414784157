import contentful from "@/contentful";

export default {
  async get() {
    const entries = await contentful.getEntries({
      content_type: "projects",
      order: "sys.createdAt"
    });

    const parsedData = contentful.parseEntries(entries);

    console.log(parsedData);

    const data = parsedData.items.map(item => {
      return {
        id: item.sys.id,
        title: item.fields.title,
        subtitle: item.fields.subtitle,
        description: item.fields.description,
        slug: item.fields.slug,
        image: item.fields.image
      };
    });

    console.log("data", data);

    return data;
  }
};
