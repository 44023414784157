<template lang="pug">
  .projects
    section
      .container: .row
        .col-xs-12
          new-project

    section.midtone
      .container: .row
        .col-xs-12
          .project-list
            router-link(v-for="project in projects" :to="{ name: 'project-details', params: { slug: project.slug, }}")
              flip-card
                template(v-slot:front)
                  img(:src="imageUrl(project.image)")
                template(v-slot:back)
                  h3 {{ project.title }}
                  p {{ project.subtitle }}
                  p {{ project.description }}
</template>

<script>
import Projects from "@/contentful/Projects";
import FlipCard from "@/components/FlipCard.vue";
import NewProject from "@/components/NewProject.vue";

export default {
  name: "projects",
  components: {
    FlipCard,
    NewProject
  },
  data: () => {
    return {
      projects: []
    };
  },
  async created() {
    this.projects = await Projects.get();
  },
  methods: {
    imageUrl: image => {
      return (
        process.env.VUE_APP_CLOUDINARY_URL +
        "/c_fill,g_north,h_250,w_250/" +
        image
      );
    }
  }
};
</script>

<style scoped lang="sass">

.project-list
  display: flex
  justify-content: center

  .flip-card
    margin: 2rem
</style>
